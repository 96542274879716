

body {
    /*background: rgb(116, 116, 116) !important;*/
    background:#fff;
}
.card {    
    border: 0px solid black;
}

.logo-login {
    margin-top: 100px;
    padding: 18px;    
}

.card-login {
    margin-top: 0px;
    max-width: 30rem;
}

.card-body {
    color:black;
    background: black;
    font-family: sans-serif;
    font-weight: 600 !important;
    border-bottom: 0;
    border: 0px solid black;
}

.input-group-prepend span{
    background-color: #000;
    color: #fff;
    border:0 !important;
}

input:focus{
    outline: 0 0 0 0  !important;
    box-shadow: 0 0 0 0 !important;
}

.login_btn{
    width: 130px;
}



.btn-outline-danger {
    color: #fff;
    font-size: 18px;
    background-color: #28a745;
    background-image: none;
    border-color: #28a745;
}

.form-control-login {
    display: block;
    width: 50%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    line-height: 1.6;
    
    background-clip: padding-box;
    border: 2px solid #fff;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inpudt-group-prepend{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    
    
    text-align: center;
    white-space: nowrap;
    background-color: black;
    border: 2px solid white;
    border-radius: 0;
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.6;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: black;
    border: 2px solid black;
    border-radius: 0;
}

.recover_password{
    color: white;
    background-color: black;
    
    
}

.error_response{
    color: red;
    background-color: black;
    
    
}


.footer {
    position:fixed;
  }

